.table-task {
    margin-top: 15px;


    &__info {
        max-width: 800px;
        width: calc(100% - 15px);
        margin-right: 15px;
        padding: 15px;
        background-color: white;
        border-radius: 14px 3px 3px 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__title {
                display: flex;
                align-items: center;
                padding-bottom: 4px;
                border-bottom: 1px solid #dfdfdf;
                width: 70%;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0.3px;

                &__text {
                    width: 100% !important;
                    left: 0px !important;
                    margin-top: 0px !important;
                    margin-bottom: 0px !important;
                }
            }

            &__sync {
                white-space: nowrap;
                display: flex;
                background-color: #b9f1a4;
                padding: 2px 10px;
                text-align: center;

                border-radius: 18px;
                font-size: 12.5px;
                font-weight: 300;
                letter-spacing: 0.5px;
                align-items: center;
            }


        }

        &__description {
            margin-top: 10px !important;
            font-weight: 400;
            margin-left: 10px;
            cursor: pointer;

            &:hover {
                color: #1890ff !important
            }
        }


    }

    &__users {
        width: 25%;
        padding: 15px;
        background-color: white;
        border-radius: 3px 14px 14px 3px;
        font-weight: 300;
        position: relative;

        &__delete {
            top: -7px;
            width: calc(100% - 8px);
            position: absolute;
            display: flex;
            justify-content: flex-end;

        }

        &__item {
            padding: 5px;
            border-bottom: 1px solid #dfdfdf;

            &__label {
                color: #9c9b9b !important;
            }

            &__names {
                display: flex;
                flex-wrap: wrap;

                &__item {
                    margin-right: 4px;
                    font-weight: 400;
                }
            }
        }


    }


}

.no-sync {
    background-color: #fff7d4;
}

.done-task {
    background-color: #e9f8e041;
}