.chapters {
    background-color: white;
    width: 80%;
    max-width: 700px;
    border-radius: 7px;
    padding: 20px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    &:active {
        background-color: #fefefe;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 6px;
    }

    @media screen and (max-width: 450px) {
        width: 90%;

        flex-direction: column-reverse;
        align-items: flex-start;
    }

    &__actions {
        padding: 5px;
        margin-top: -10px;

        @media screen and (max-width: 450px) {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

    }
}