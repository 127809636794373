.react-tel-input {
    width: auto;

    &:hover {
        .form-control {
            border: 1px solid #1890ff
        }

        .flag-dropdown {
            border: 1px solid #1890ff;
            border-right-color: #cacaca;
        }

    }
}

.react-tel-input .form-control.invalid-number {
    border: 1px solid #ff4d4f;
    background-color: #fff;
}

.react-tel-input .flag-dropdown.invalid-number {
    border: 1px solid #ff4d4f;
    background-color: #fff;
}

.react-tel-input .form-control {
    border-radius: 0%;
    height: 32px;


}

.react-tel-input .flag-dropdown {
    border-radius: 0px;
    background-color: rgb(255, 255, 255);

    .react-tel-input .form-control:hover {

        background-color: rgb(241, 24, 24)
    }


}