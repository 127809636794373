.item {
    background-color: white;
    width: 80%;
    border-radius: 7px;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 10px;

    // &:hover {
    //     cursor: pointer;
    //     box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    // }

    // &:active {
    //     background-color: #fefefe;
    //     cursor: pointer;
    //     box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 6px;
    // }

}