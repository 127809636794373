.file-upload {
    display: flex !important;
    width: 100%;
}


.add-icon {
    color: red !important
}

.add-text {
    color: #1890ff;
    margin-right: 5px;

    &:hover {
        color: #368ddf;
    }
}

.file-upload span {
    display: flex !important;
}

.add-container {
    margin-top: 5px;
    padding-left: 3px;
    padding-right: 3px;

    border-radius: 8px;
    display: flex;
    align-items: center !important;
    margin-right: 10px;

    align-items: center;

    &:hover {
        background-color: rgb(240, 240, 240);
    }

    &:active {
        background-color: #d8ecff;
    }
}