.nodename-user {
    font-size: 16px;
    fill: #1890ff;
    font-weight: 500;
    font-family: sans-serif;
    stroke: none !important;
}

.description-user {
    font-size: 16px;
    fill: #777;
    font-weight: 500;
    font-family: sans-serif;
    stroke: none !important;
}

.user-circle {
    fill: #9db1c2;
}