.group-subheader {
  font-size: 16px;
  font-weight: 500;
}

.table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-group {
  display: flex;
  margin-left: 25px;
  padding: 4px;

  .column-group {
    padding: 4px;
    border-right: 1px solid red;

    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    &__cells {
      display: flex;
      width: 100%;

      height: 100%;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: space-between;
    }

    &__title {
    }
  }

  &__cell {
    margin-right: 10px;
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-right: 0px;
    }

    justify-content: space-between;

    &__label {
    }

    &__value {
    }
  }
}

.items-container {
  width: 100%;

  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 10px;
  border: 1px solid rgb(199, 198, 198);
  border-top: none;
}

.layer {
  &__1 {
    font-size: 14px;
    font-weight: 600;
    // background-color: rgb(169, 205, 234);
  }

  &__2 {
    font-size: 13px;
    font-weight: 500;
    // background-color: rgb(169, 205, 234, 0.5);
  }

  &__3 {
    margin-left: 10px;
    //background-color: rgb(169, 205, 234, 0.3);
  }

  &__4 {
    margin-left: 10px;
    //background-color: rgb(169, 205, 234, 0.1);
  }
}

.container {
  width: 100%;
  margin-bottom: 10px;

  &__header {
    font-size: 18px;
    border: 1px solid black;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: none;
    font-weight: 600;
    padding: 5px;
    background-color: #d1d1d1;
  }

  &:nth-child(odd) {
    .container__header {
      background-color: #d1d1d1;
      border: none;
    }

    color: #000000;
  }

  &:nth-child(even) {
    .container__header {
      background-color: #c9d3da;
      border: none;
    }

    color: rgb(0, 120, 209);
  }
}

.row-card {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 15px;

  &:nth-child(odd) {
    background-color: #f9f9f9fa;
  }

  &:nth-child(even) {
    background-color: #f2f2f2fa;
  }

  margin-bottom: 10px;
}
