.tasks {
    width: 100%;
    padding: 20px 20px 25px;


    &__filter {
        width: 100%;
        margin-top: 20px;
        font-weight: 300;
        letter-spacing: 0.3px;
        display: flex;
        align-items: flex-end;
        font-size: 16px !important;

        &__inputs {}

        &__actions {
            white-space: nowrap;
            display: flex;
            align-items: center;
        }
    }

    &__group {


        max-width: 800px;

        &__title {
            font-weight: 500;
            letter-spacing: 0.3px !important;
            font-size: 20px !important;
        }
    }

    &__add-new {
        display: flex;
        margin-bottom: 10px;
        width: 100%;

        &__task {
            max-width: 800px;
            width: calc(100% - 15px);
            margin-right: 15px;
            padding: 15px;
            background-color: white;
            border-radius: 14px 3px 3px 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-weight: 300;

            &:hover {
                background-color: rgb(248, 252, 255);

            }

            &:active {
                background-color: rgb(241, 249, 255);
            }
        }

        &__group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px;
            background-color: white;
            border-radius: 3px 14px 14px 3px;
            font-weight: 300;
            max-width: 400px;

            cursor: pointer;

            &:hover {
                background-color: rgb(248, 252, 255);

            }

            &:active {
                background-color: rgb(241, 249, 255);
            }
        }
    }

    &__load {
        width: 100%;
        max-width: 800px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__items {
        width: 100%
    }
}