.task {
  display: flex;
  cursor: pointer;
  margin: 10px 0px;
  letter-spacing: 0.3px;

  &__overbg {
    z-index: 111;
    position: absolute;
    width: calc(815px + 25%);
    background-color: #ffffffa0;
    height: 100%;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__result {
      padding: 0px !important;
    }
  }

  &__info {
    max-width: 800px;
    width: calc(100% - 15px);
    margin-right: 15px;
    padding: 15px;
    background-color: white;
    border-radius: 14px 3px 3px 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__menu {
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        color: #1890ff;
        user-select: "none";

        &:hover {
          background-color: #1890ff14;
        }

        &:active {
          background-color: #1890ff04;
        }
      }

      &__title {
        display: flex;
        align-items: center;
        padding-bottom: 4px;
        border-bottom: 1px solid #dfdfdf;
        width: 70%;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.3px;

        &__text {
          width: 100% !important;
          left: 0px !important;
          margin-top: 0px !important;
          margin-bottom: 0px !important;

          &:hover {
            color: #1890ff !important;
          }
        }
      }

      &__sync {
        display: flex;
        background-color: #b9f1a4;
        padding: 2px 10px;
        text-align: center;
        border-radius: 18px;
        font-size: 14.5px;
        font-weight: 300;
        letter-spacing: 0.5px;
        align-items: center;
        margin-right: 5px;
      }

      &__no-sync {
        display: flex;
        background-color: #fff7d4;
        padding: 2px 10px;
        margin-right: 5px;
        text-align: center;
        min-width: 50px;
        border-radius: 18px;
        font-size: 14.5px;
        font-weight: 300;
        letter-spacing: 0.5px;
        align-items: center;
      }
    }

    &__description {
      margin-top: 10px !important;
      font-weight: 400;
      margin-left: 10px;
    }
  }

  &__users {
    width: 25%;
    padding: 15px;
    background-color: white;
    border-radius: 3px 14px 14px 3px;
    font-weight: 300;
    position: relative;
    z-index: 1;

    &__delete {
      top: -15px;
      right: -15px;
      width: calc(100% - 8px);
      position: absolute;
      display: flex;
      justify-content: flex-end;
    }

    &__item {
      padding: 5px;
      border-bottom: 1px solid #dfdfdf;

      &__label {
        color: #9c9b9b !important;
      }

      &__names {
        display: flex;
        flex-wrap: wrap;

        &__item {
          margin-right: 4px;
          font-weight: 400;
        }
      }
    }
  }
}

.showme {
  background: #00000000;
  animation: showtask 1.5s ease;
}

@keyframes showtask {
  50% {
    background-color: #96b69631;
  }

  100% {
    background-color: #00000000;
  }
}
