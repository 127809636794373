@mixin flex-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.cart-preview {
  // padding-top: 10px;

  &__header {
    @include flex-container;
  }

  &__delete-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__delete-items-container {
    @include flex-container;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 24px;
  }

  &__items-container {
    @include flex-container;
    flex-wrap: wrap;
    width: 100%;
  }

  &__items-name {
    font-weight: 500;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__items-amount {
    // margin-left: 20px;
    width: 50%;
  }

  &__items-price {
    width: 50%;
    text-align: right;
    font-weight: 500;
  }

  &__total {
    @include flex-container;
    font-weight: 500;
    font-size: 18px;
    padding-right: 24px;
    margin-top: 20px;
  }

  // &__total-title {
  //   font-size: 18px;
  // }

  &__buttons {
    margin-top: 20px;
    @include flex-container;
    padding-right: 24px;
  }

  .ant-divider-horizontal {
    margin: 16px 0;
  }
}