.select-skeleton {
    width: 100%;
    min-height: 32px;
    align-items: center;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 1px 24px 1px 4px;
    display: flex;
    flex-wrap: wrap;
}

.select-value {
    width: 100%;
    min-height: 32px;
    align-items: center;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 1px 24px 1px 0px;
    display: flex;
    flex-wrap: wrap;

}

.ant-skeleton-paragraph>li {
    padding-top: 6px;
}

.ant-skeleton-paragraph {
    margin: 3px;
}