.expanded {
    width: 100%;
    height: 100%;
    background-color: white;

    &__pickers {
        display: flex;
        flex-direction: row;
        margin: 20px;
        margin-top: 10px
    }

    &__loading {
        min-height: 200px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__table {
        min-height: 160px;
        margin: 20px,
    }
}