.values {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;



    &__value {

        border-radius: 7px;
        display: flex;
        width: 100%;
        flex-direction: column;




        &__label {
            font-size: 12px;
        }

        &__number {
            font-size: 30px;
        }

        &__old {

            margin-left: 10px;
            padding: 5px;
            color: #ff8484;
            border-radius: 7px;

        }
    }
}

.once_value {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__value {
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__label {
            font-size: 12px;
        }

        &__number {
            color: rgba(0, 0, 0, 0.733);
            font-weight: 600;
            font-size: 40px;
        }

        &__old {
            margin-top: 10px;
            padding: 5px 10px;
            border: 1px solid #ff8484;
            color: #ff8484;
            border-radius: 7px;

        }
    }
}