.col {
    z-index: 2;
    padding-top: 12px;
    padding-right: 12px;
    min-height: 100%;

    padding-bottom: 12px;

    &:not(:first-child) {
        padding: 12px;

    }

    align-items: center;

    &__new {
        height: 100%;
        width: 100%;

        background-color: #ffffff11;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: #ffffffa2;
        }

    }
}

.preset {
    height: 100%;
    width: 100%;

    background-color: #ffffffee;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 10px;

    &:hover {
        //background-color: #5c95ca33;
    }

    &__graph {
        &__new {
            cursor: pointer;
            width: 100%;
            padding: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__label {
            font-size: 15px;
            margin-left: 10px;
            margin-top: 3px;
            font-weight: 500;
            letter-spacing: 0.3px;
        }

        &__period {
            color: #999999;
            font-size: 11px;
            margin-left: 10px;
            margin-right: 10px;
            font-weight: 400;
            letter-spacing: 0.3px;
            margin-bottom: 6px;
        }

        &__checkbox {
            margin-top: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__color {
            margin-top: 3px;
            margin-right: 15px;
        }
    }
}