.sidebar {
  &__heading {
    padding-left: 6px;
    color: white !important;
    height: 32px;
    margin: 40px 25%;
    width: 50%;
  }

  &__menu {
    width: 80% !important;
    margin: 10px 10% !important;
    background-color: transparent !important;
  }

  &__text {
    font-size: 16px;
    font-weight: 600;
  }

  &__menu-item {
    border-radius: 8px;
    margin-top: 10px;
  }

  &__menu-icon {
    font-size: 16px !important;
  }

  &__user {
    color: white;
    text-align: center;
    width: 80%;
    margin: 10px 10%;
    margin-top: 0;
  }

  &__user-collapsed {
    right: -50%;
    transform: translateX(-50%);
    margin-bottom: 30px;
  }

  &__username {
    margin-top: 10px;
    color: white !important;
  }

  &__user-desc {
    margin-bottom: 30px;
  }
}

.ant-layout-sider {
  // background-color: #6734ff;
  background: rgb(52, 27, 130);
  background: linear-gradient(180deg, rgba(52, 27, 130, 1) 0%, rgba(103, 52, 255, 1) 100%);
}

.ant-layout-sider-zero-width-trigger-left {
  background: rgb(52, 27, 130);
}

.ant-layout-sider-trigger {
  display: none !important;
}

.ant-layout-sider-zero-width-trigger {
  display: none;
  // top: 12px;
  // right: -50px;
  // background-color: white !important;
  // color: black !important;
}

.ant-menu-inline-collapsed-tooltip {
  @media screen and (max-width: 450px) {
    display: none !important;
  }
}