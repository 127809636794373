.form-row {
    margin-bottom: 10px;
    margin-top: 5px;

    &__item {
        display: flex;
        flex-direction: column;


        &__label {
            font-size: 15px;
            letter-spacing: 0.2px;
            font-weight: 600;
            margin-bottom: 4px;
        }
    }
}

.choose_charts {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    &__chart {
        margin-top: 5px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        border-radius: 15px;

        &:hover {
            background-color: #c2dcf7ee;
        }
    }


}

.seted {
    background-color: #6cb2f3aa;
}