.header-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__mode {

        display: flex;
        margin-right: 10px;

        &__label {
            color: rgb(88, 92, 95);
            margin-right: 5px;
            margin-top: -1px;
        }
    }
}

ul {
    list-style-type: none;
    list-style: none;
    padding: 0;
    margin: 0;
}

ul li {
    list-style-type: none;
    list-style: none;
    padding: 0;
    margin: 0;
}

.row {


    display: flex;
    align-items: flex-start;
    width: 100%;

    &__delete-icon {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        z-index: 5;
        position: absolute;
    }

    &__col {
        z-index: 2;
        padding-top: 12px;
        padding-right: 12px;
        min-height: 100%;

        padding-bottom: 12px;

        &:not(:first-child) {
            padding: 12px;

        }

        align-items: center;

        &__new {
            height: 100%;
            width: 100%;

            background-color: #ffffff11;
            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: #ffffffa2;
            }

        }
    }
}

.preset_item {
    margin: 15px;
}

.dashboard__select {
    display: flex;
    align-items: center;
    margin-top: 15px;

    &__label {
        display: flex;
        align-items: center;
        margin-top: 0px;
        margin-right: 20px;
        margin-bottom: 0px;
        font-size: 18px;
    }

    &__input {
        width: 90%;
        max-width: 200px;
    }
}

.delete-row-action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 5;
    position: absolute;
}