$primary: #1890ff;
$dark: #001529;
$accent: #3aafb1;
$border-primary: #00bac4;
$border-primary-2: #00a8b133;


@mixin hover {
  &:hover {
    box-shadow: 0px 1px 3px 1px #008a9177;
  }

  &:active {
    box-shadow: 0px 1px 2px 1px #0ca4ac5d;
  }
}

@mixin no-active {
  box-shadow: none
}

@mixin card {
  border: 1px solid $border-primary;
}

@mixin badge {
  font-size: 12px;
  font-weight: 400;
  background-color: $accent;
  color: white;
  padding: 1.5px 7px 1px 7px;
  border-radius: 8px;
  // position: absolute;
  // top: -10px;
  // right: -40px;
}