.legends {
    padding-left: 10px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-bottom: 5px;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #eae6e6;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #bdbabaee;
        border-radius: 10px;
        border: 1px none #ffffff;
    }

    &__legend {
        margin-right: 10px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        &__color {
            margin-right: 5px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: red;
        }

        &__text {
            display: flex;
            white-space: nowrap;
            font-size: 12px;
        }
    }

    * {
        scrollbar-width: auto;
        scrollbar-color: #bdbabaee #eae6e6;
    }


}