@import "../styles/Variables.scss";

.signin {
    width: 100vw;
    height: 100vh;

    &__form {
        width: 420px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        padding: 40px 30px 25px !important;

        @media screen and (max-width: 450px) {
            width: 100%;

            border: none;
            padding: 40px 35px 25px !important;
        }
    }

    &__image-container {
        width: 150px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__image-frame {
        background: $primary;
        padding: 10px;
        border-radius: 8px;
    }

    &__image-description {
        font-size: 14px;
        font-weight: 600;
        text-align-last: center;
    }

    .username-form {
        max-width: 400px;
    }

    .username-form-forgot {
        float: right;
    }

    .ant-col-rtl .username-form-forgot {
        float: left;
    }

    .username-form-button {
        width: 100%;
    }

    #logo {
        fill: tomato;
    }
}