@import "../../../../styles/Variables.scss";

.ant-modal {
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px var(--primary-color);
    overflow: hidden;
}

.modal {

    &__col {
        background-color: #5c95ca22;
        height: 65px;
        padding: '8px 0';



    }

    .row_active {
        border-right: 1px solid #6cb2f3ff;
        border-left: 1px solid #6cb2f3ff;
    }

    .hover {

        background-color: #6cb2f367;
    }

    .active {
        background-color: #6cb2f3ff;
    }

    &__row {
        &:hover {

            border-right: 1px solid #6cb2f355;
            border-left: 1px solid #6cb2f355;
        }
    }
}

.row-presset {
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #cfcfcf;

    &:hover {
        @include hover;
        border: 1px solid #adebee;
    }

}

.row_divider {
    margin-top: 5px;
    margin-bottom: 5px;
}