.nodename-task {
    font-size: 16px;
    fill: #000;
    line-height: 1.42857143 !important;
}

.description-task {
    font-size: 16px;
    fill: #777;
    stroke: none;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.result-task {
    font-size: 16px;
    width: 300px;
    fill: #6d996d;
    stroke: none;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.nodename-task:hover {
    fill: #1890ff;
}

.result-foreign {
    background-color: white;
    z-index: 3;
}