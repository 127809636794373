.date {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;

    &__radio {
        margin-bottom: 10px;
        margin-top: 10px;
    }
}